<template>
    <section class="content">
        <div class="table-responsivex">
            <table class="table table-hover" ref="tableuser" style="width: 100%;">
                <thead>
                    <tr>
                        <th>Kode barang</th>
                        <th>Nama Barang</th>
                        <th>Deskripsi</th>
                        <th>Brand</th>
                        <th>Sisa</th>
                        <th>Aksi</th>
                    </tr>
                </thead>
                <tbody @click="handleClick">
                </tbody>
            </table>
        </div>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Barang</label>
                                <input type="hidden" name="row_id" value="" v-model="form.id"/>
                                <select v-model="form.barang_id" class="form-control">
                                    <option v-for="item in optBarang" v-bind:value="item.id">{{ item.label }}</option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Harga Beli</label>
                                <input id="email" class="form-control email" v-model="form.harga_beli" type="text=" required="required" name="email" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Tanggal</label>
                                <datepicker v-model="form.transaksi_tanggal" :options="{format:'dd/mm/yyyy', startView: 'days', minViewMode: 'days'}" :change="onChange()"/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Tipe Transaksi</label>
                                <select v-model="form.transaksi_tipe" class="form-control" @input="changeLabel($event)">
                                    <option v-for="item in optType" v-bind:value="item.id">{{ item.label }}</option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label qty">Qty</label>
                                <input id="email" class="form-control email" v-model="form.qty" type="text=" required="required" name="email" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Cabang</label>
                                <select v-model="form.branch_id" class="form-control">
                                    <option v-for="item in optCabang" v-bind:value="item.id">{{ item.label }}</option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Keterangan</label>
                                <textarea class="form-control" v-model="form.keterangan"></textarea>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from 'moment';
import datepicker from '@/components/Datepicker';

export default {
    name: 'DataGuru',
    components: {
        vSelect,
        datepicker,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            formTitle: 'Tambah',
            optCabang: [],
            optBarang: [],
            optType: [
                {id: '0', label: 'Barang Masuk'},
                {id: '1', label: 'Barang Keluar'},
                {id: '2', label: 'Stock Opname'}
            ],
            form: {
                barang_id: '',
                harga_beli: '',
                transaksi_tanggal: '',
                transaksi_tipe: '',
                qty: '',
                branch_id: '',
                keterangan: '',
            },
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)

        authFetch('/management/inventaris/cabang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optCabang = js.data;
            })

        authFetch('/management/inventaris/barang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optBarang = js.data;
            })
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
            if (e.srcElement.getAttribute('class') == 'btn btn-primary') {
                this.$router.push('/inventaris-detail/'+e.srcElement.getAttribute('data-id'));
            }
        },
        onChange: function() {
            var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
            namahari = namahari.split(" ");
            if (this.form.tanggal) {
                var split = this.form.tanggal.split('/')
                var tgl = new Date(split[2],split[1]-1,split[0]);
                var hari = tgl.getDay();

                this.form.hari = namahari[hari];
            }
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var self = this;
            this.errors = [];

            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/management/inventaris';
            if (this.method == 'PUT') urlSubmit = '/management/inventaris/' + this.form.id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal('hide');
                });

            ev.preventDefault();
        },
        uploadFoto : function (file) {
            var file_data = $('#inputFile').prop('files');
            var form_data = new FormData();
            var fileInput = $('#inputFile')[0];
            $.each(fileInput.files, function(k,file){
              form_data.append('file', file);
            });
            form_data.append('subdir', '/data-guru/');
            form_data.append('id', this.form.id);
            this.form_data = form_data
            return false
        },
        doUpload: function() {
            return authFetch('/akademik/data_guru/upload_data', {
                method: 'POST',
                body: this.form_data,
                headers: {
                  'Content-Type': null,
                }
            })
        },
        changeLabel: function(event) {
            console.log(event.target.value)
            if (event.target.value == '2') {
                $('.qty').html('Qty Aktual');
            } else {
                $('.qty').html('Qty');
            }
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableuser, {
            "title": "Inventaris",
            "roles": this.$route.params.roles,
            "ajax": "/management/inventaris",
            scrollX: true,
            "columns": [
                { "data": "kode_barang" },
                { "data": "name" },
                { "data": "description" },
                { "data": "brand" },
                { "data": "qtysisa" },
                { "data": "brand" },
            ],
            filterBy: [0, 1, 2],
            "rowCallback": function(row, data) {
                $('td:eq(5)', row).html('<a type="button" class="btn btn-primary" data-id="'+data.id+'">Detail</a>');
            },
            buttonClick: (evt) => { 
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Data Inventaris';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Data Inventaris';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    authFetch('/akademik/data_guru/' + evt.data.id, {
                            method: 'DELETE',
                            body: 'id=' + evt.data.id
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(js => {
                            this.table.api().ajax.reload();
                        });
                }

            },
        });

    }
}
</script>